/***************************************************************************************************
 * BROWSER POLYFILLS
 */

import 'mutationobserver-shim';
import 'element-closest';

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es6';

/** Evergreen browsers require these. **/
import 'core-js/es6/reflect';
import 'core-js/es7/reflect';

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.


/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
import 'intl';  // Run `npm install --save intl`.
import 'intl/locale-data/jsonp/en';

if (process.env.ENV !== 'localdevelopment') {
	// Production
} else {
	// Development
	Error['stackTraceLimit'] = Infinity;
	require('zone.js/dist/long-stack-trace-zone');
}
